import React from 'react'
import Footer from '../../Footer/Footer'
import Navbar from '../../Navbar/Navbar'
import "../CSS/contact.css"
import Footer1 from '../../Footer1/Footer1'
import Footerlast from '../../Footerlast/Footerlast'
import logo from "../../Images/nav-ogo.png"

function Contact() {
  return (
    <>
      <Footer />
      <Navbar />
      <div className='contact-footer sm:!p-0 sm:!justify-between sm:!flex-row'>
        <div className='contact1 sm:!w-auto'>
          <h1>Contact</h1>
        </div>
        <div className='contact2 sm:!w-auto'>
          <ul className={`sm:!m-0 sm:!w-auto sm:!right-0`}>
            <li>Home</li>
            <li>/</li>
            <li>contact</li>
          </ul>
        </div>
      </div>
      <div className='contact-container'>
        <div className="contact-details sm:!ml-[0px]">
          <div className="contact-heading">
            <h1>Contact Us
            </h1>
            <img className={`sm:!ml-[165px]`} src={logo} alt="" />
          </div>
          <h4>Head Office:</h4>
          <h5> <span><i class="fa-solid fa-house"></i></span>SHREE JOB CONSULTANT</h5>
          <p>  <span><i class="fa-solid fa-location-dot"></i></span>Shop-311B, 3rd Floor, Lalganga Midas Complex,
            Opp. to Central College, Bilaspur Road, Fafadih, Raipur, Chhattisgarh. 492009</p>
          <p>  <span><i class="fa-solid fa-phone"></i></span>Telephone: +91 9179995552 / 9111104359</p>
          <p> <span><i class="fa-solid fa-at"></i></span>Email: shreejobconsultant@gmail.com</p>
          <p> <span><i class="fa-brands fa-linkedin-in"></i></span>LINKEDIN: shreejobconsultant@gmail.com</p>
        </div>
        {/* <div className="contact-map">
         <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d45304.35119770689!2d81.59575589930509!3d21.299945857201646!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1sen!2sin!4v1728892493769!5m2!1sen!2sin" width="550" height="450" style={{ border: '0' }}allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
         </div> */}
      </div>
      <Footer1 />
      <Footerlast />
    </>
  )
}

export default Contact
