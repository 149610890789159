import React, { useEffect, useState } from "react";
import "../CSS/Currentopening.css";
import Footer from "../../Footer/Footer";
import Footer1 from "../../Footer1/Footer1";
import Navbar from "../../Navbar/Navbar";
import list from "./list.json";
import Footerlast from "../../Footerlast/Footerlast";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import Backend_url from "../../../Important/BackEnd";
import Token from "../../../Important/TOKEN";
import axios from "axios";
import SendEnquiry from "./SendEnquiry";
import { Icon } from "@iconify/react/dist/iconify.js";

function Currenopening() {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Number of items to display per page
  const [expandedJobId, setExpandedJobId] = useState(null); // Track which job is expanded
  const [applyClickedJobId, setApplyClickedJobId] = useState(null); // Track which job "Apply now" is clicked twice
  const [jsonlist, setjsonlist] = useState([]);
  const [openModal, setopentModal] = useState(false);
  const navigate = useNavigate(); // Hook to programmatically navigate

  // Calculate the current items to display based on current page and items per page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = jsonlist.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate total pages
  const totalPages = Math.ceil(jsonlist?.length / itemsPerPage);

  const handleJobClick = (jobId) => {
    // Toggle the expansion of job details
    setExpandedJobId(expandedJobId === jobId ? null : jobId);
    setApplyClickedJobId(null); // Reset the apply button tracking
  };

  const handleApplyClick = (jobId) => {
    if (applyClickedJobId === jobId) {
      // Second click: Navigate to the career form page
      // navigate(`/career/${jobId}`); // Navigates to the career form page for the specific jobId
      setopentModal(true);
    } else {
      // First click: Expand job details and track the clicked jobId
      setExpandedJobId(jobId);
      setApplyClickedJobId(jobId);
    }
  };

  const fetchjson = async () => {
    try {
      axios
        .get(`${Backend_url}/jsonroute/get`, {
          headers: {
            Authorization: "Bearer " + Token,
          },
        })
        .then((res) => {
          const alljson = res.data.json;
          alljson.map((jsondata) => {
            const parsedObject = JSON.parse(jsondata.JsonObject);
            if (parsedObject.name == "currentop") {
              const reverse = [...parsedObject?.data].reverse();
              console.log(reverse);
              setjsonlist(reverse);
              fetchfromcurrentop();
            }
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  // ------------------fetchfromcurrentop------------------------
  const fetchfromcurrentop = async () => {
    try {
      debugger;
      await axios
        .get(`${Backend_url}/currentop/all/cop`, {
          headers: {
            Authorization: "Bearer " + Token,
          },
        })
        .then((res) => {
          console.log(res.data);
          const alldata = [...res.data.data].reverse();

          setjsonlist((prev) => [...alldata, ...prev]);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchjson();
  }, []);

  useEffect(() => {
    console.log(jsonlist);
  }, [jsonlist]);

  return (
    <>
      <Footer />
      <Navbar />
      <div className="current sm:!flex-row sm:!py-[5px] sm:gap-[4px]">
        <div className="current-job1 sm:flex sm:items-center sm:justify-center">
          <h1 className={` sm:!text-[16px]`}>CURRENT OPENINGS</h1>
        </div>
        <div className="current-job2">
          <ul className={`sm:!m-0 sm:!right-0 sm:!w-auto`}>
            <li className={`sm:!p-0`}>Home</li>
            <li className={`sm:!p-0`}>/</li>
            <li className={`sm:!p-0`}>Current job</li>
          </ul>
        </div>
      </div>

      {/* Job List */}
      <div className="current-opening">
        <div className="current-opening1">
          {currentItems.map((item) => (
            <div className="data-container" key={item.id}>
              <div className="heading">
                <div className="h1">
                  <h1
                    className="text-[#389ADC]"
                    onClick={() => handleJobClick(item.id)}
                  >
                    <span className={`text-xl`}>&#8226;</span> {item.Heading}
                  </h1>
                </div>
                <div className="button">
                  <button
                    className="btn"
                    onClick={() => handleApplyClick(item.id)}
                  >
                    {expandedJobId === item.id && applyClickedJobId === item.id
                      ? "Send Enquiry"
                      : "Apply now"}
                  </button>
                </div>
              </div>

              {/* Toggle job details and hide the job list */}
              {expandedJobId === item.id ? (
                <div className="job-detail-dropdown">
                  <ul>
                    <li className={`for-mobile-gap`}>
                      <strong style={{ display: "flex" }}>
                        <i className="fa fa-location-dot"></i>
                        Location:
                      </strong>{" "}
                      {item.location}
                    </li>
                    <li className={`for-mobile-gap`}>
                      <strong>
                        <i class="fa-solid fa-briefcase"></i>
                        Experience:
                      </strong>{" "}
                      {item.Expreriance}
                    </li>
                    <li className={`for-mobile-gap`}>
                      <strong style={{ display: "flex" }}>
                        <Icon
                          icon="icon-park-solid:people"
                          width="18"
                          height="18"
                        />
                        Post:
                      </strong>{" "}
                      {item.post}
                    </li>
                    <li className={`for-mobile-gap`}>
                      <strong style={{ display: "flex" }}>
                        <Icon
                          icon="tdesign:money-filled"
                          width="18"
                          height="18"
                        />
                        Salary:
                      </strong>{" "}
                      {item.Salary}
                    </li>
                    <li className={`for-mobile-gap`}>
                      <strong style={{ display: "flex" }}>
                        <Icon
                          icon="stash:data-date-solid"
                          width="18"
                          height="18"
                        />
                        Date:
                      </strong>{" "}
                      {item.Date}
                    </li>
                  </ul>
                  <div className="key-container">
                    <div className="tag">Key Skills:</div>
                    <div className="data-content">
                      <p>{item.key}</p>
                    </div>
                  </div>
                  <div className="description-container">
                    <div className="tag">Description:</div>
                    <div className="data-content">
                      <p>{item.Description}</p>
                    </div>
                  </div>
                </div>
              ) : (
                <ul>
                  <li>
                    <span> Number of Post:</span> {item.post}
                  </li>
                  <li>
                    <span>Salary:</span> {item.Salary}
                  </li>
                  <li>
                    <span> Last Date:</span> {item.Date}
                  </li>
                </ul>
              )}
            </div>
          ))}

          {/* Pagination */}
          <div className="button1">
            {[...Array(totalPages)].map((_, index) => (
              <button
                key={index + 1}
                className={`btn1 ${currentPage === index + 1 ? "active" : ""}`}
                onClick={() => setCurrentPage(index + 1)}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>
      </div>
      {openModal === true ? (
        <SendEnquiry openModal={openModal} setopentModal={setopentModal} />
      ) : null}
      <Footer1 />
      <Footerlast />
    </>
  );
}

export default Currenopening;
